import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import ReactRecaptcha3 from 'react-google-recaptcha3';




const ThreeComp = () => {

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [isUsingEmail, setIsUsingEmail] = useState(false);
  const [fullName, setFullName] = useState('');
  const [mobileInfo, setMobileInfo] = useState('');
  const [emailInfo, setEmailInfo] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [pledgeSent, setPledgeSent] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [otpInputs, setOtpInputs] = useState(['', '', '', '', '', '']); // Array to store OTP inputs
  const [countdown, setCountdown] = useState(9); // Countdown timer
  const [otpSent, setOtpSent] = useState(true); // Flag to indicate whether OTP is sent
  const [otpVerified, setOtpVerified] = useState(false); // Flag to indicate OTP verification status
  const inputRefs = useRef([]);
  const [countrycode, setCountrycode] = useState([]);
  const [otp, setOTP] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(countrycode[0]);
  console.log(otp, "otp")

  const [requestStatus, setRequestStatus] = useState({
    isPledgeLoading: false
  });




  const handleChange = (e) => {
    const { value } = e.target;
    if (!(/^\d*$/).test(value)) {
      return; // Exit the function if non-numeric characters are found
    }
    setOTP(value);
    // setError('');
  };

  // Function to handle OTP input change
  const handleOtpInputChange = (index, value) => {
    // Ensure only numeric characters are allowed
    if (!(/^\d*$/).test(value)) {
      return; // Exit the function if non-numeric characters are found
    }

    const newInputs = [...otpInputs];
    newInputs[index] = value;
    setOtpInputs(newInputs);

    // Highlighted modification: Move focus to the next input field if a digit is entered
    // if (value.length === 1 && index < otpInputs.length - 1) {
    //   inputRefs.current[index + 1].focus();
    // }

    // // Highlighted modification: Move focus to the previous input field if a digit is deleted
    // if (value.length === 0 && index > 0) {
    //   inputRefs.current[index - 1].focus();
    // }
  };



  // Function to handle Resend OTP click
  const handleResendOtp = () => {
    setCountdown(9);
    let response;
    ReactRecaptcha3.getToken().then(
      async (resp) => {
        try {
          if (emailInfo) {
            const data = {
              type: 'email',
              email: emailInfo,
              recaptchaResponse: resp,
              fullName: fullName,
              user: 'user'
            };
            response = await axios.post('https://apiv4.goqii.com/uht/send_otp', data);
            // console.log('API response:', response);
          } else {
            const data = {
              type: 'mobile',
              mobileNumber: mobileInfo,
              extention: selectedCode ? selectedCode : '91',
              recaptchaResponse: resp,
              user: 'user',
              fullName: fullName
            };
            response = await axios.post('https://apiv4.goqii.com/uht/send_otp', data);
            // console.log('API response:', response);
          }
        } catch (error) {
          alert('Something went wrong');
          console.error('Error:', error);
        }
      })
  };

  // Function to handle Next click
  const handleNextClick = () => {
    // const otp = otpInputs.join('');
    if (otp.length === 6) {
      // Call API to verify OTP
      verifyOtp(otp);
    } else {
      alert('Please enter complete OTP.');
    }
  };

  // Function to verify OTP by sending it to API
  const verifyOtp = async (otp) => {
    ReactRecaptcha3.getToken().then(
      async (resp) => {
        try {
          if (isUsingEmail) {
            const data = {
              email: emailInfo,
              otp: otp,
              recaptchaResponse: resp
            };
            setSubmitting(true)
            const response = await axios.post('https://apiv4.goqii.com/uht/verify_otp', data);
            // console.log('API response:', response.data.message);
            if (response.data.message === 'OTP has been successfully verified.') {
              // OTP verification successful
              setOtpVerified(true);
            } else {
              // OTP verification failed
              alert('Invalid OTP. Please try again.');
            }
          } else {
            const data = {
              mobileNumber: mobileInfo,
              otp: otp,
              recaptchaResponse: resp
            };
            setSubmitting(true)
            const response = await axios.post('https://apiv4.goqii.com/uht/verify_otp', data);
            // console.log('API response:', response.data.message);

            if (response.data.message === 'OTP has been successfully verified.') {
              // OTP verification successful
              setOtpVerified(true);
            } else {
              // OTP verification failed
              alert('Invalid OTP. Please try again.');
            }
          }
          // Make API request to verify OTP
        } catch (error) {
          console.error('Error verifying OTP:', error);
          alert('An error occurred while verifying OTP. Please try again later.');
        } finally {
          setSubmitting(false);
        }
      })
  };

  // Function to start countdown timer
  const startCountdown = () => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => prevCountdown - 1);
    }, 1000);
    return timer;
  };

  // Start countdown timer when component mounts
  React.useEffect(() => {
    if (pledgeSent && countdown > 0) {
      const timer = startCountdown();
      return () => clearInterval(timer);
    }
  }, [pledgeSent, countdown]);


  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  useEffect(() => {
    ReactRecaptcha3.init(sitekey).then(
      (status) => {
        // console.log(status);
      }
    );
  }, []);

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression for phone number validation
    const phoneRegex = /^\d{10}$/; // Change this regex according to your requirements
    return phoneRegex.test(phoneNumber);
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id === 'fullName') {
      // Validate name
      if (!/^[a-zA-Z\s]*$/.test(value)) {
        // If value contains anything other than alphabets or spaces
        return; // Don't update state
      }
      setFullName(value);
    } else if (id === 'email') {
      setEmailInfo(value);
    } else if (id === 'phone') {
      // Limit phone number to 10 numeric characters only
      const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
      if (numericValue.length > 10) {
        // alert('Phone number should not exceed 10 digits.');
        return;
      }
      setMobileInfo(numericValue);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowToast(false);
    if (!otpVerified) {
      setPledgeSent(false)
      setMobileInfo('')
      setEmailInfo('')
      setOtpInputs(['', '', '', '', '', ''])
      setFullName('')
    }
    // resetForm();
  };

  const handlePledgeClick = () => {
    let response;
    if (fullName.trim() === '') {
      alert('Please enter your name.');
      return;
    }
    if (isUsingEmail && !validateEmail(emailInfo)) {
      alert('Please enter a valid email address.');
    } else if (!isUsingEmail && !validatePhoneNumber(mobileInfo)) {
      alert('Please enter a valid phone number.');
    } else {
      ReactRecaptcha3.getToken().then(
        async (resp) => {
          try {
            setRequestStatus({
              ...requestStatus,
              isPledgeLoading: true
            });
            if (emailInfo) {
              const data = {
                type: 'email',
                email: emailInfo,
                recaptchaResponse: resp,
                user: 'user',
                fullName: fullName
              };

              response = await axios.post('https://apiv4.goqii.com/uht/send_otp', data);
              console.log('API response:', response);
            } else {
              const data = {
                type: 'mobile',
                mobileNumber: mobileInfo,
                extention: selectedCode ? selectedCode : '91',
                recaptchaResponse: resp,
                user: 'user',
                fullName: fullName
              };
              console.log("dta", data);

              response = await axios.post('https://apiv4.goqii.com/uht/send_otp', data);
              console.log('API response:', response);
            }

            if (response && response.data?.code === 200) {
              // Send the pledge  
              setPledgeSent(true);
            } else {
              alert('Something went Wrong');
            }

          } catch (error) {
            alert("Something Went Wrong");
            console.error('Error:', error);
          }
          finally {
            setRequestStatus({
              ...requestStatus,
              isPledgeLoading: false
            });
          }
        })

    }
  };



  const handleUseEmailClick = () => {
    setIsUsingEmail(prevState => !prevState);
    // setContactInfo('')
  };

  // const renderOtpInputs = () => {
  //   return otpInputs.map((value, index) => (
  //     <div key={index} className='col-2'>
  //       <input
  //         type="text"
  //         maxLength={1}
  //         value={value}
  //         className="form-control partner-uht-field text-center"
  //         autoComplete="off"
  //         onChange={(e) => handleOtpInputChange(index, e.target.value)}
  //         ref={(input) => (inputRefs.current[index] = input)}
  //       />
  //     </div>
  //   ));
  // };


  const renderOtpInputs = () => {
    return (
      <div className='col-12 text-center'>
        <input
          type="text"
          value={otp}
          onChange={handleChange}
          maxLength={6}
          className='form-control partner-uht-field text-center'
          style={{ width: "60%", margin: "0 auto", letterSpacing: "20px" }}
        // ref={(input) => (inputRefs.current[index] = input)}
        />
      </div>
    )
    // );
  };


  const verifiedContent = (
    <>
      <main className="site-content text-center col-12">
        <div className="site-content__center mb-4">
          <h3 className="" style={{ fontWeight: '700' }}>
            100 UHT Rewards Earned
          </h3>
          <h5 className=" mb-0 mt-5" style={{ fontSize: "16px" }}>
            You can access the 100 UHT Rewards on the GOQii App when you sign up with the <b>{isUsingEmail ? 'Email ID' : 'Phone Number'}</b> you submitted.
          </h5>
          <h4 className=" mb-0 mt-5" style={{ fontSize: "20px" }}>
            Take your commitment to the next level by downloading our GOQii app:
          </h4>

          <div className="cta-app mt-3">
            <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Pledge"
              target='_blank'
              className="cta-app w-inline-block">
              <img
                src="images/download_on_the_app_store_badge_us-uk_rgb_blk_092917.svg"
                loading="lazy"
                width="181"
                alt=""
              />
            </a>
            <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Pledge"
              target='_blank'
              className="cta-app w-inline-block">
              <img
                src="images/playstore.svg"
                loading="lazy"
                width="209"
                alt=""
              />
            </a>
          </div>
          {/* <div className='row mt-3'>
                <div className='col-6'>
                  <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Pledge" target='_blank'><img src='/public/images/download_on_the_app_store_badge_us-uk_rgb_blk_092917.svg' className="img-fluid" alt='' /></a>
                </div>
                <div className='col-6'>
                  <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Pledge" target='_blank'><img src='/public/images/playstore.svg' className="img-fluid" alt='' /></a>
                </div>
              </div> */}
        </div>
      </main>
    </>
  );

  const fetchCountyCode = async () => {
    try {
      const response = await axios.post('https://apiv4.goqii.com/uht/country_code');
      console.log('county response:', response.data.data);
      setCountrycode(response.data.data)
      // Do something with the response data here
    } catch (error) {
      console.error('Error fetching country code:', error);
      // Handle errors here
    }
  };

  const handleCountryChange = (event) => {
    const selectedCode = event.target.value;
    setSelectedCode(selectedCode)
    const country = countrycode.find((c) => c.code === selectedCode);
    console.log(country, "country")
    setSelectedCountry(country);
  };


  useEffect(() => {
    fetchCountyCode()

  }, []);

  return (
    <>
      <div className="body">
        <div className="container">
          <div className="hero-wrapper-two">
            <h1 className="heading">Gamifying Good Health</h1>
            <p className="heading---normal header">
              Reach Your Health Goals and get rewarded via Universal Health
              Tokens
            </p>
            <p className="heading---normal bold---small">
              Take Health Pledge &amp; Earn 100 UHT Rewards
            </p>
            <button onClick={() => setShowModal(true)} className="button-primary purple w-button">
              EARN NOW
            </button>
          </div>
          <div
            style={{ paddingTop: "56.17021276595745%" }}
            className="video w-video w-embed"
          >
            <iframe
              className="embedly-embed"
              src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FetMKvXZgNhE%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DetMKvXZgNhE&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FetMKvXZgNhE%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              allowFullScreen
              title="The philosophy behind GOQii Health Metaverse in Web 3.0"
            ></iframe>
          </div>
          <div className="w-layout-blockcontainer features-wrapper w-container">
            <div className="w-layout-blockcontainer features w-container">
              <div className="div-block">
                <img
                  src="images/community.svg"
                  loading="lazy"
                  width="70"
                  alt=""
                  className="image-2"
                />
                <p className="subheading">Communities</p>
              </div>
              <p className="paragraph">
                People can earn tokens by engaging in various healthy activities
                such as exercising, tracking their fitness progress, or
                participating in wellness challenges.
              </p>
            </div>
            <div className="w-layout-blockcontainer features w-container">
              <div className="div-block">
                <img
                  src="images/professional.svg"
                  loading="lazy"
                  width="38"
                  alt=""
                  className="image-2"
                />
                <p className="subheading">Certified Professionals</p>
              </div>
              <div className="paragraph">
                Certified Pros can offer their services and expertise through
                token exchanges, allowing them to expand their client base.
              </div>
            </div>
            <div className="w-layout-blockcontainer features w-container">
              <div className="div-block">
                <img
                  src="images/marketplace.svg"
                  loading="lazy"
                  width="56"
                  alt=""
                  className="image-2"
                />
                <p className="subheading">Health Marketplaces</p>
              </div>
              <div className="paragraph">
                Health marketplaces can integrate Universal Health Token as a
                means of enabling users to access products, services, or digital
                health solutions using their accumulated tokens
              </div>
            </div>
            <div className="w-layout-blockcontainer features w-container">
              <div className="div-block">
                <img
                  src="images/organic.svg"
                  loading="lazy"
                  width="56"
                  alt=""
                  className="image-2"
                />
                <p className="subheading">Organisations &amp; Institutions</p>
              </div>
              <p className="paragraph">
                Healthcare organisations can accept this health token as well as
                reward patients for proactive health management, adherance to
                treatment plans and achieving positive health outcomes
              </p>
            </div>
          </div>
        </div>
        <div className="container how-it-works">
          <div className="content how-it-works">
            <div className="sublabel-wrapper">
              <img
                src="images/how-20it-20works.svg"
                loading="lazy"
                width="20"
                alt=""
                className="image-2"
              />
              <p className="sublabel">How it Works</p>
            </div>
            <p className="heading---content">
              Every Healthy <br />
              Behaviour Counts
            </p>
            <p className="paragraph">
              A first of its kind web 3.0 offering that seamlessly integrates
              all elements of preventive care into a cohesive and immersive user
              experience.
            </p>
            <div className="w-layout-hflex flex-block how-it-works">
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/active.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">Stay Active</p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/healthy.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">Eat Healthy</p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/sleep.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">Sleep Well</p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/meditate.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">Meditate</p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/monitor.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">Monitor Vitals</p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/interact.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">
                  Interact <br />
                  with Experts
                </p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/connect.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">
                  Connect &amp; <br />
                  Share
                </p>
              </div>
              <div className="icons-wrapper">
                <div className="benefits-icon">
                  <img
                    src="images/records.svg"
                    loading="lazy"
                    width="36"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="sublabel icons">
                  Store Health <br />
                  Records
                </p>
              </div>
            </div>
          </div>
          <img
            src="images/how_it_works.png"
            loading="lazy"
            sizes="(max-width: 479px) 94vw, (max-width: 767px) 96vw, (max-width: 991px) 92vw, 38vw"
            srcSet="
          images/how_it_works-p-500.png 500w,
          images/how_it_works.png       691w
        "
            alt=""
            className="image-3"
          />
        </div>
        <div className="w-layout-vflex powered-section-goqii no-bg">
          <div className="container how-it-works">
            <div className="content goqii">
              <div className="goqii-heading">
                <p className="heading---content goqii">Powered By</p>
                <img
                  src="images/goqii.svg"
                  loading="lazy"
                  width="147"
                  alt=""
                  className="image-2"
                />
              </div>
              <p className="sublabel goqii">
                Empowering you to regain control of your health
              </p>
              <p className="paragraph black">
                With a focus on prevention and early intervention, our
                personalised healthcare platform delivers tailored guidance from
                skilled general practitioners, certified coaches and experts
                that enable you to make lasting, positive lifestyle changes.
              </p>
              <div className="w-layout-hflex flex-block goqii">
                <div className="icons-wrapper">
                  <div className="benefits-icon goqii">
                    <img
                      src="images/condition.svg"
                      loading="lazy"
                      width="36"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons">
                    Condition <br />
                    Management
                  </p>
                </div>
                <div className="icons-wrapper">
                  <div className="benefits-icon goqii">
                    <img
                      src="images/health.svg"
                      loading="lazy"
                      width="36"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons">
                    Health <br />
                    Ecosystem
                  </p>
                </div>
                <div className="icons-wrapper">
                  <div className="benefits-icon goqii">
                    <img
                      src="images/data.svg"
                      loading="lazy"
                      width="36"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons">
                    Data &amp; <br />
                    Diagnostics
                  </p>
                </div>
                <div className="icons-wrapper">
                  <div className="benefits-icon goqii">
                    <img
                      src="images/insurance.svg"
                      loading="lazy"
                      width="36"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons">
                    Insurance <br />
                    Solutions
                  </p>
                </div>
                <div className="icons-wrapper">
                  <div className="benefits-icon goqii">
                    <img
                      src="images/payment.svg"
                      loading="lazy"
                      width="36"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons">
                    Payment <br />
                    Solutions
                  </p>
                </div>
              </div>
              <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=start" className="button-primary purple small w-button"
                target="_blank"
              >
                START TODAY
              </a>
            </div>
            <div className="yt-wrapper">
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="w-embed-youtubevideo youtube"
              >
                <iframe
                  src="https://www.youtube.com/embed/etMKvXZgNhE?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                  frameBorder="0"
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    pointerEvents: "auto",
                  }}
                  allow="autoplay; encrypted-media"
                  allowFullScreen
                  title="GOQii Health Metaverse Video"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Modal */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModal ? 'block' : 'none', zIndex: '9999' }}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content" style={{ backgroundColor: '#e6f7ff', borderRadius: '15px' }}>
            <div className="modal-header border-0 pb-0">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleModalClose}></button>
            </div>
            <div className="modal-body pt-0">
              <main className="site-content text-center col-12 my-3">
                {!pledgeSent ? (
                  <>
                    <div className="site-content__center mb-4">
                      <h4 className="fw-bold">Take the Pledge for a Healthier You!</h4>
                      <h5 className="mb-0 mt-3" style={{ fontSize: "16px" }}>
                        By taking our pledge, you're making a promise to prioritize your health and well-being. Together, we can support each other on this journey towards a healthier lifestyle.
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <div className="mb-3">
                          <input
                            type="text"
                            id="fullName"
                            className="form-control"
                            placeholder="Full Name"
                            autoComplete="off"
                            value={fullName}
                            onChange={handleInputChange}
                            style={{ borderRadius: '10px' }}
                          />
                        </div>
                        <div>
                          {isUsingEmail ? (
                            <div className="mb-3">
                              <input
                                type="email"
                                id="email"
                                className="form-control"
                                placeholder="Email"
                                autoComplete="off"
                                value={emailInfo}
                                onChange={handleInputChange}
                                style={{ borderRadius: '10px' }}
                              />
                            </div>
                          ) : (
                            <div className='row'>
                              <div className='col-5'>
                                <select value={countrycode.code} className="form-select mb-3" onChange={handleCountryChange} style={{ borderRadius: '10px' }}>
                                  {countrycode.map((country, index) => (
                                    <option key={index} value={country.code}>
                                      {country.country_name} (+{country.code})
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className='col-7'>
                                <input
                                  type="text"
                                  id="phone"
                                  className="form-control mb-3"
                                  placeholder="Phone Number"
                                  autoComplete="off"
                                  value={mobileInfo}
                                  onChange={handleInputChange}
                                  style={{ borderRadius: '10px' }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div className='col-12 text-end px-0 mb-3'>
                          <a className="btn btn-link" onClick={handleUseEmailClick}>{isUsingEmail ? 'Use Phone Number' : 'Use Email'}</a>
                        </div>
                      </div>
                    </div>
                    <div className='col-12 text-center mt-3 mb-4'>
                      <h5 className="mb-0" style={{ fontSize: "16px" }}>
                        "I pledge to prioritize my health by making mindful choices in diet, exercise, and self-care."
                      </h5>
                    </div>
                    <div className="col-12 text-center mb-3">
                      <button className="btn btn-primary" onClick={handlePledgeClick}
                        disabled={requestStatus.isPledgeLoading && "disabled"}
                      >
                        {requestStatus.isPledgeLoading ? "Saving" : "I Pledge"}
                      </button>
                    </div>
                  </>
                ) : (
                  <div>
                    {!otpVerified ? (
                      <div className="site-content__center mb-4">
                        <h5 className="fw-bold mb-4">
                          We sent you a One Time Password (OTP) via {isUsingEmail ? 'email' : 'text message'} to {isUsingEmail ? emailInfo : mobileInfo}
                        </h5>
                        <h5 className="mb-3" style={{ fontSize: "16px" }}>
                          Enter OTP here
                        </h5>
                        <div className='row justify-content-center'>
                          {renderOtpInputs()}
                          <div className="col-12 text-center my-3">
                            <h4 className="mb-0" style={{ fontSize: "16px" }}>
                              {countdown > 0 ? `00:0${countdown} seconds` : ''}
                            </h4>
                            {countdown === 0 && (
                              <button className="btn btn-link" onClick={handleResendOtp}>
                                Resend OTP
                              </button>
                            )}
                          </div>
                          <div className="col-12 text-center my-4">
                            <button
                              className="btn btn-primary"
                              disabled={submitting}
                              onClick={handleNextClick}
                              aria-disabled={submitting}
                            >
                              {submitting ? 'Verifying' : 'Next'}
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>{verifiedContent}</>
                    )}
                  </div>
                )}
              </main>
            </div>
          </div>
        </div>
      </div>
      {showModal && <div className="modal-backdrop fade show" onClick={handleModalClose}></div>}
    </>
  );
};

export default ThreeComp;