import React, { useState } from 'react'; 
import axios from 'axios';
import ReactRecaptcha3 from 'react-google-recaptcha3';



const LastComp = () => {

  const [email, setEmail] = useState("");
  const [newLetterMessage, setNewsLetterMessage] = useState("");

  const onEmailChagne = (e) => { 
     setEmail(e.target.value); 
  }

  // save newletter emil 
  async function saveEmail(e){ 
    e.preventDefault(); 
    let resp  = await ReactRecaptcha3.getToken(); 

    let response; 
    try{ 
      response = await axios.post('https://apiv4.goqii.com/uht/newsleter', {email, recaptchaResponse: resp});     
      
      if(response.data?.code === 200){ 
        setNewsLetterMessage(response?.data?.message); 
        setEmail(""); 
        setTimeout(() => { 
          setNewsLetterMessage(""); 
        }, 3000); 
      }else{ 
        alert('Something went wrong'); 
      }

    }catch(e){ 
      alert('Something went Wrong'); 
    } 
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
    <section className="footer-light">
      <div className="container-2">
        <div className="footer-wrapper-two">
          <div className="w-layout-vflex footer-brand">
            <a href="#" className="footer-brand w-inline-block"
              ><img src="images/uht-20logo.svg" loading="lazy" alt=""
            /></a>
            <p className="paragraph footer-small">
              Rewarding UHT for staying healthy.<br />Powering the one-stop
              health ecosystem GOQii
            </p>
            <div className="footer-social-block-two">
                {/* <a href="#" className="footer-social-link w-inline-block">
                  <img src="images/linkedin-in.svg" loading="lazy" alt="" />
                </a> */}
                <a href="https://discord.com/invite/Jv98rZFeSE"  target="blank" className="footer-social-link w-inline-block">
                  <img
                    src="images/discord-icon-svgrepo-com (1).svg"
                    loading="lazy"
                    alt=""
                    style={{ width: "20px" }}
                  />
                </a>
                <a href="https://telegram.me/+0p0JIMiSh0QxMmM1"  target="blank" className="footer-social-link w-inline-block">
                  <img
                    src="images/telegram-svgrepo-com (1).svg"
                    loading="lazy"
                    width="20"
                    alt=""
                  />
                </a>
                <a
                  href="https://x.com/UHTofficial"
                  target="blank"
                  className="footer-social-link w-inline-block"
                >
                  <img src="images/x.svg" loading="lazy" alt="" />
                </a>
              </div>
          </div>
          <div className="footer-block-two">
            <div className="footer-title">Sitemap</div>
            <a href="https://uht.gitbook.io/universal-health-token/" target="blank" className="footer-link-two">Whitepaper</a
            ><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('our-partners'); }} className="footer-link-two">Our Partners</a
            ><a href="#" onClick={(e) => { e.preventDefault(); scrollToSection('fog-foundation'); }} className="footer-link-two">FoG Foundation</a>
          </div>
          <div className="footer-form w-form">
            <form
              id="wf-form-Footer-Form"
              name="wf-form-Footer-Form"
              data-name="Footer Form"
              className="footer-form-container"
              data-wf-page-id="66b03f0e43860e1fe4b30c00"
              data-wf-element-id="539b027b-3552-5d54-b49d-70da7e77f62c"
              onSubmit={saveEmail}
            >
              <div className="footer-title">Our Letter</div>
              <div className="footer-form-block">
                <input
                  className="footer-form-field w-input"
                  maxLength="256"
                  name="Footer-Email"
                  data-name="Footer Email"
                  aria-label="Get product updates"
                  placeholder="Enter Email"
                  type="email"
                  id="Footer-Email"
                  value={email}
                  onChange={e=> onEmailChagne(e)}
                  required
                /><input
                  type="submit"
                  data-wait="Please wait..."
                  className="footer-form-submit w-button"
                  value=""
                />

              </div>
              
              <p className='ml-5' style={{color: "green"}}>{newLetterMessage.length > 0 ? newLetterMessage: ""}</p>
            </form>
            <div className="w-form-done">
              <div>Thank you! Your submission has been received!</div>
            </div>
            <div className="w-form-fail">
              <div>Oops! Something went wrong while submitting the form.</div>
            </div>
          </div>
          <div className="footer-block-two">
            <div className="footer-title">Contact Us</div>
            <a href="#" className="footer-link-two">info@forceofgood.xyz</a
            ><a href="#" className="footer-link-two"
              >Force of Good Ltd., Jalya Place, 2nd Floor,<br />Road Town,
              Tortola, British Virgin Island<br />VG1110</a
            >
          </div>
        </div>
        <div className="footer-divider-two"></div>
        <div className="footer-bottom">
          <div className="footer-copyright">
            © 2024 Force of Good Foundation. All rights reserved
          </div>
        </div>
      </div>
    </section>
    </>
  )
}

export default LastComp