import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from './components/Dashboard';
import Home from './components/Home';

function App() {

  const currentdomain = window.location.hostname;

  if (currentdomain == 'forceofgood.xyz' || currentdomain == 'www.forceofgood.xyz') {
    document.title = 'Force of Good Foundation';
    var favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = 'https://appcdn.goqii.com/storeimg/27137_1696400033.ico'; // Replace with the path to your new favicon

    // Find the existing favicon link element (if any) and remove it
    var existingFavicon = document.querySelector("link[rel='icon']");
    if (existingFavicon) {
      existingFavicon.parentNode.removeChild(existingFavicon);
    }

    // Add the new favicon link element to the head of the document
    document.head.appendChild(favicon);
  }


  if (currentdomain == "www.uht.xyz" || currentdomain == "uht.xyz" || true) {
    document.title = 'Universal Health Token';
    var favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = 'https://appcdn.goqii.com/storeimg/37857_1693380632.png'; // Replace with the path to your new favicon

    // Find the existing favicon link element (if any) and remove it
    var existingFavicon = document.querySelector("link[rel='icon']");
    if (existingFavicon) {
      existingFavicon.parentNode.removeChild(existingFavicon);
    }

    // Add the new favicon link element to the head of the document
    document.head.appendChild(favicon);
  }

  return (
    <>
      <Routes>
        <Route path="/">
          {/* <Route exact path="/" element={<Navigate to="/" />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/txn-dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;