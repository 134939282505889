import React, { useState, useEffect } from 'react';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const OneComp = () => {

  const [showModal, setShowModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state
  const [requestStatus, setRequestStatus] = useState({ 
    isPartnerSubmitLoading: false
  });

  // const customBreakpoint = 768; // Define your custom breakpoint here
  // const isMobile = window.innerWidth <= customBreakpoint;

  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  useEffect(() => {
    ReactRecaptcha3.init(sitekey).then(
      (status) => {
        console.log(status);
      }
    );
  }, []);
  console.log("sitekey",sitekey,process.env.RECAPTCHA_SITE_KEY)

  const [formData, setFormData] = useState({
    fullName: '',
    OrganizationInput: '',
    EmailInput: '',
    MobileInput: '',
    WebsiteInput: '',
  })


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validateMobileNumber = (mobileNumber) => {
    // Allow an optional plus sign followed by digits, and then exactly 10 digits.
    const mobileNumberRegex = /^(\+?\d{1,2})?\d{10}$/;
    return mobileNumberRegex.test(mobileNumber.replace(/\D/g, ''));
  };

  const resetForm = () => {
    setFormData({
      fullName: '',
      OrganizationInput: '',
      EmailInput: '',
      MobileInput: '',
      WebsiteInput: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    

    if (!validateEmail(formData.EmailInput)) {
      alert('Invalid email address');
      return;
    }

    // Validate mobile number
    if (!validateMobileNumber(formData.MobileInput)) {
      alert('Invalid mobile number');
      return;
    }

    if (formData.fullName.trim() === '') {
      alert('Full Name cannot be blank');
      return;
    }

    if (formData.OrganizationInput.trim() === '') {
      alert('Organization Name cannot be blank');
      return;
    }

    ReactRecaptcha3.getToken().then(
      async (resp) => {
        const data = {
          fullName: formData.fullName,
          organizationName: formData.OrganizationInput,
          emailAddress: formData.EmailInput,
          mobileNumber: formData.MobileInput,
          websiteURL: formData.WebsiteInput,
          recaptchaResponse: resp
        };
        
        setRequestStatus({ 
          ...requestStatus, 
          isPartnerSubmitLoading: true
        }); 

        try {

          const response = await axios.post('https://apiv4.goqii.com/uht/user_formdata', data);
          // console.log('API response:', response);
          // handleModalClose(); 
          // handleToast()
          
          console.log(response.data.code);  
          
          if(response.data?.code === 200){ 
            handleToast();  
            
          }else{ 
            alert('Something went wrong'); 
          }         
        } catch (error) {
          alert('Something went wrong'); 
          console.error('Error:', error);
        }finally{ 
          setRequestStatus({ 
            ...requestStatus,   
            isPartnerSubmitLoading: false
          }); 
        }
      })
      

  };

  const handleModalClose = () => {
    setShowModal(false);
    setShowToast(false);
    resetForm();
  };

  const handleToast = () => {
    setShowToast(true);
  };


  const handleLinkClick1 = () => {
    // Simulate link click
    // You can replace "/new-route" with the actual route you want to navigate to
    window.location.reload();
    window.location.href = "/our-partners";
  };

  return (
    <>
      <section className="body">
        <div className="w-layout-vflex bg_container">
          <div id='fog-foundation' className="container fog">
            <div className="content fog">
              <p className="heading---content fog">Force of Good Foundation</p>
              <p className="heading---content small thin _36-height">
                Revolutionizing Healthy Initiatives
              </p>
            </div>
            <div className="div-block-6">
              <img
                src="images/rectangle-202812.png"
                loading="lazy"
                sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, (max-width: 991px) 78vw, 700px"
                srcSet="
                images/rectangle-202812-p-500.png 500w,
                images/rectangle-202812.png       763w
              "
                alt=""
                className="image-3"
              />
              <div className="force-of-good-explanation">
                <div className="investors-logo">
                  <img
                    src="images/1.svg"
                    loading="lazy"
                    width="94"
                    alt=""
                    className="image-2"
                  />
                </div>
                <p className="paragraph">
                  Force of Good foundation is centered around the idea of
                  promoting and incentivizing healthy living for individuals.
                  Its core principles revolve around the belief that living a
                  healthy lifestyle should be not only beneficial for one&#x27;s
                  well-being but also personally rewarding. Force of Good
                  Foundation has partnered with GOQii, a leading preventive
                  health-tech company, to distribute UHT through their platform.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id='our-partners' className="container partner-section div-block-7">
          <div className="content partner-heading">
            <div className="sublabel-wrapper">
              <img
                src="images/check.svg"
                loading="lazy"
                width="20"
                alt=""
                className="image-2"
              />
              <p className="sublabel">Established</p>
            </div>
            <p className="heading---content middle">Investors &amp; Partners</p>
          </div>
          <div className="partners-wrapper">
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/goqii-1.svg"
                  loading="lazy"
                  width="133"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/animaco.png"
                  loading="lazy"
                  width="138"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/tezos-foundation-201.png"
                  loading="lazy"
                  width="141"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/polygon_blockchain_logo-201.png"
                  loading="lazy"
                  width="190"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/image-2016.png"
                  loading="lazy"
                  width="80"
                  alt=""
                  className="image-2"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/image-203.png"
                  loading="lazy"
                  width="153"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/image-205.png"
                  loading="lazy"
                  width="167"
                  alt=""
                  className="image-2 no-max-width"
                />
              </div>
            </div>
            <div className="partner">
              <div className="investors-logo goqii">
                <img
                  src="images/coto-torple-icon-201.png"
                  loading="lazy"
                  width="190"
                  alt=""
                  className="image-2"
                />
              </div>
            </div>
          </div>
          <button onClick={() => setShowModal(true)} className="button-primary purple small w-button">
            Partner With UHT
          </button>
        </div>
        <div className="container partner-section">
          <div className="content advisor-heading">
            <div className="sublabel-wrapper">
              <img
                src="images/how-20it-20works.svg"
                loading="lazy"
                width="20"
                alt=""
                className="image-2"
              />
              <p className="sublabel">Trusted</p>
            </div>
            <p className="heading---content">Advisors</p>
          </div>
          <div className="w-layout-vflex flex-block-5">
            <div className="w-layout-hflex advisor-wrapper core">
              <div className="advisor-item core">
                <div className="advisor yat-siu">
                  <a className="linked-in-core" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/yatsiu/"></a>
                </div>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Yat Siu</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Co-founder &amp; Chairman
                    <br />
                    Animoca Brands
                  </p>
                </div>
              </div>
              <div className="advisor-item core">
                <div className="advisor vishal-gondal">
                   <a className="linked-in-core" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/vishalgondal/"></a>
                </div>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Vishal Gondal</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    GOQii Foundation
                  </p>
                </div>
              </div>
              <div className="advisor-item core">
                <div className="advisor zeneca">
                   <a className="linked-in-core" style={{cursor: 'pointer'}}  target="blank" href="https://x.com/Zeneca"></a>
                </div>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong>Zeneca</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder
                    <br />
                    Zen Academy
                  </p>
                </div>
              </div>
            </div>
            <div className="w-layout-hflex advisor-wrapper">
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor agatya-saman">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/agastya-samat-090a16148/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Agastya Samat</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Director
                    <br />
                    Force of Good Foundation
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor mohammed-ezeldin">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/mohamed-ezeldin-07859339/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Mohamed Ezeldin</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Head of Tokenomics
                    <br />
                    Animoca Brands
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor kavita-gupta">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/kavita-gupta-71a4352/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Kavita Gupta</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founding Managing Partner
                    <br />
                    Delta Blockchain Fund &amp;
                    <br />
                    Fintech.TV
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor tarum-katial">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/tarunkatial/ "></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Tarun Katial</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    Coto
                  </p>
                </div>
              </div>
            </div>
            <div className="w-layout-hflex advisor-wrapper">
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor lauren-selig">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/lauren-s-4ba188/ "></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Lauren Selig</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Founder &amp; CEO
                    <br />
                    Shake and Bake
                    <br />
                    Productions
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor manish-agarwal">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manish-agarwal-6586482/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Manish Agarwal</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Council Member
                    <br />
                    Kratos Gaming Network
                    <br />
                    (KGen)
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor manish-dureja">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manishdureja/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">Manish Dureja</strong>
                  </p>
                  <p className="paragraph black center-allign">
                    Ex MD &amp; CEO
                    <br />
                    Intermiles
                  </p>
                </div>
              </div>
              <div className="advisor-item">
                <a href="#" className="w-inline-block">
                  <div className="advisor manoj-narenden-madnadi">
                    <div className="investors-logo goqii"></div>
                    <a className="linkedin" style={{cursor: 'pointer'}}  target="blank" href="https://www.linkedin.com/in/manoj-narender-madnani/"></a>
                  </div>
                </a>
                <div className="w-layout-vflex flex-block-3">
                  <p className="paragraph black center-allign semi-large">
                    <strong className="advisor-name">
                    Manoj Narender Madnani
                    </strong>
                  </p>
                  <p className="paragraph black center-allign">
                    MD
                    <br />
                    Marathon (EMEA)
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-layout-vflex powered-section-goqii">
          <div className="container mobile-app">
            <div className="div-block-4">
              <div className="w-layout-blockcontainer features-wrapper mobile-app w-container">
                <div className="w-layout-blockcontainer features mobile-app w-container">
                  <div className="div-block">
                    <img
                      src="images/professional.svg"
                      loading="lazy"
                      width="38"
                      alt=""
                      className="image-2"
                    />
                    <p className="subheading">
                      Health
                      <br />
                      Analysis
                    </p>
                  </div>
                  <p className="paragraph small">
                    Get unlimited cloud storage on the health locker to securely
                    upload health records and test results, which you can share
                    with your personal coach or doctor.
                  </p>
                </div>
                <div className="w-layout-blockcontainer features mobile-app w-container">
                  <div className="div-block">
                    <img
                      src="images/marketplace.svg"
                      loading="lazy"
                      width="56"
                      alt=""
                      className="image-2"
                    />
                    <p className="subheading">
                      GOQii
                      <br />
                      Arena
                    </p>
                  </div>
                  <p className="paragraph small">
                    A social feature that lets you discover and interact with
                    likeminded people. Chat with friends, explore challenges,
                    and view updates while you shift to healthier lifestyle.
                  </p>
                </div>
                <div className="w-layout-blockcontainer features mobile-app w-container">
                  <div className="div-block">
                    <img
                      src="images/organic.svg"
                      loading="lazy"
                      width="56"
                      alt=""
                      className="image-2"
                    />
                    <p className="subheading">Karma</p>
                  </div>
                  <p className="paragraph small">
                    Healthcare organisations can accept this health token as
                    well as reward patients for proactive health management,
                    adherance to treatment plans and achieving positive health
                    outcomes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mobile-app">
            <div className="div-block-3">
              <img
                src="images/img-lifestyle1-201.png"
                loading="lazy"
                width="581"
                sizes="(max-width: 479px) 100vw, 581px"
                alt=""
                srcSet="
                images/img-lifestyle1-201-p-500.png 500w,
                images/img-lifestyle1-201.png       567w
              "
                className="phone"
              />
            </div>
            <div className="w-layout-hflex flex-block mobile-app">
              <p className="paragraph black">

                The only preventive care ecosystem that delivers a 360<sup>&deg;</sup> solution
                to needs of the conscious and ever changing consumer is stepping
                into the future of health with web 3.0
              </p>
              <div className="div-block-5">
                <div className="icons-wrapper cta-app">
                  <div className="benefits-icon cta-app">
                    <img
                      src="images/vector-1.svg"
                      loading="lazy"
                      width="51"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons cta-app">
                    <strong>
                      9+ Years
                      <br />
                    </strong>
                    In Operation
                  </p>
                </div>
                <div className="icons-wrapper cta-app">
                  <div className="benefits-icon cta-app">
                    <img
                      src="images/download.svg"
                      loading="lazy"
                      width="46"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons cta-app">
                    <strong>
                      5M+
                      <br />
                    </strong>
                    Downloads
                  </p>
                </div>
                <div className="icons-wrapper cta-app">
                  <div className="benefits-icon cta-app">
                    <img
                      src="images/users.svg"
                      loading="lazy"
                      width="45"
                      alt=""
                      className="image-2"
                    />
                  </div>
                  <p className="sublabel icons cta-app">
                    <strong>
                      450K+
                      <br />
                    </strong>
                    Montly Active
                    <br />
                    Users
                  </p>
                </div>
              </div>
              <div className="cta-app">
                <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Download" 
                target='_blank'
                className="cta-app w-inline-block">
                  <img
                    src="images/download_on_the_app_store_badge_us-uk_rgb_blk_092917.svg"
                    loading="lazy"
                    width="181"
                    alt=""
                  />  
                </a>
                <a href="https://goqii.com/app/?fsn=47&utm_source=UHT&utm_medium=website&utm_campaign=Download" 
                target='_blank'
                className="cta-app w-inline-block">
                  <img
                    src="images/playstore.svg"
                    loading="lazy"
                    width="209"
                    alt=""
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Modal */}
      <div className={`modal fade ${showModal ? 'show' : ''}`} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: showModal ? 'block' : 'none' }}>
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content" style={{ backgroundColor: '#e6f7ff', borderRadius: '15px' }}>
      <div className="modal-header border-0 pb-0">
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleModalClose}></button>
      </div>
      <div className="modal-body pt-0">
      {showToast === false ? (
        <main className="site-content text-center col-12">
          <div className="site-content__center mb-4">
            <h2 className="fw-bold text-primary">
              Partner with UHT
            </h2>
            <h5 className="mb-3" style={{ fontSize: "16px", color: "#333" }}>
              Interested in learning more? Fill out this form and a team member will reach out to you.
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="mb-3">
                <input
                  type="text"
                  id="fullName"
                  className="form-control"
                  value={formData.fullName}
                  onChange={handleChange}
                  placeholder="Full Name"
                  autoComplete="off"
                  style={{ borderRadius: '10px' }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  id="OrganizationInput"
                  className="form-control"
                  value={formData.OrganizationInput}
                  onChange={handleChange}
                  placeholder="Organization Name"
                  autoComplete="off"
                  style={{ borderRadius: '10px' }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  id="EmailInput"
                  className="form-control"
                  value={formData.EmailInput}
                  onChange={handleChange}
                  placeholder="Email address"
                  autoComplete="off"
                  style={{ borderRadius: '10px' }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="MobileInput"
                  value={formData.MobileInput}
                  onChange={handleChange}
                  placeholder="Mobile number (With Country Code)"
                  autoComplete="off"
                  style={{ borderRadius: '10px' }}
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  id="WebsiteInput"
                  className="form-control"
                  value={formData.WebsiteInput}
                  onChange={handleChange}
                  placeholder="Website URL"
                  autoComplete="off"
                  style={{ borderRadius: '10px' }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <button className="btn btn-primary" onClick={handleSubmit} style={{ borderRadius: '10px', padding: '10px 20px' }}
                disabled={requestStatus.isPartnerSubmitLoading && "disabled"}
              >
                {requestStatus.isPartnerSubmitLoading? "Submitting": "Submit"}
              </button>
            </div>
          </div>
        </main>
      ) : (

        <main className="site-content text-center col-12">
          <div className="site-content__center mb-4">
            <h2 className="fw-bold text-success">
              Thank You!
            </h2>
            <h5 className="mb-0" style={{ fontSize: "16px", color: "#333" }}>
              Partner Application received. A Team member will reach out to you.
            </h5>
          </div>
        </main>
      )}
      </div>
    </div>
  </div>
</div>
{showModal && <div className="modal-backdrop fade show" onClick={handleModalClose}></div>}
    </>
  );
};

export default OneComp;
