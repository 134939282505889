import React, { useState, useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
import './Dashboard.css';
import axios from 'axios';


Chart.register(...registerables);

const Dashboard = () => {
    const [dateRange, setDateRange] = useState('today');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [totalTransaction, setTotalTransaction] = useState('');
    const [totalTransactionCount, setTotalTransactionCount] = useState('');
    const [totalWallet, setTotalWallet] = useState('');
    const [totalWalletCount, setTotalWalletCount] = useState('');
    const [selectedChain, setSelectedChain] = useState('AllChain');
    const [transactions, setTransactions] = useState([]);
    const [showAllTransactions, setShowAllTransactions] = useState(false);
    const [expandedTransaction, setExpandedTransaction] = useState(null);

    // const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY

    // useEffect(() => {
    //     console.log("11111")
    //     ReactRecaptcha3.init(sitekey).then(
    //         (status) => {
    //             console.log("token>>>>>>")
    //             setRecaptchaInitialized(true); // Mark reCAPTCHA as initialized
    //         }

    //     ).catch((error) => {
    //         console.error('Error initializing reCAPTCHA:', error);
    //     });
    // }, []);

    let transactionsChart = null;

    const updateChart = (transactionsByDate) => {

        const labels = Object.keys(transactionsByDate);
        const data = Object.values(transactionsByDate);

        console.log(labels, "labels")
        console.log(data, "data")

        const chartData = {
            labels: labels,
            datasets: [{
                label: 'Transactions',
                data: data,
                fill: false,
                borderColor: '#36A2EB',
                backgroundColor: '#36A2EB',
                tension: 0.1
            }]
        };

        const config = {
            type: 'line',
            data: chartData,
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        display: true
                    },
                    tooltip: {
                        callbacks: {
                            label: function (tooltipItem) {
                                return `Transactions: ${tooltipItem.raw}`;
                            }
                        }
                    }
                },
                scales: {
                    x: {
                        title: {
                            display: true,
                            text: 'Days'
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: 'Number of Transactions'
                        }
                    }
                }
            }
        };

        if (transactionsChart) {
            transactionsChart.destroy(); // Destroy previous chart instance
        }

        transactionsChart = new Chart(
            document.getElementById('transactionsChart'),
            config
        );
    };

    const fetchChartTransactions = async () => {
        try {
            const response = await axios.post('https://apiv4.goqii.com/uht/transaction_data_last_7_days');

            console.log('API response:', response.data.data);

            const transactionsByDate = Array.isArray(response.data.data)
                ? response.data.data.reduce((acc, transaction) => {
                    const date = transaction.logDate;
                    acc[date] = parseInt(transaction.addressCount, 10); // Assign the addressCount directly to the date
                    return acc;
                }, {})
                : {};

            console.log(transactionsByDate, "transactionsByDate");
            return transactionsByDate;

        } catch (error) {
            console.error('Error fetching transactions:', error);
            return {};
        }

    };


    const fetchTransactions = async (startDate, endDate) => {
        try {
            const response = await axios.post('https://apiv4.goqii.com/uht/transaction_data', {
                startDate: startDate,
                endDate: endDate
            });
            console.log('API response 1:', response.data.addressCount);
            setTotalTransaction(response.data.addressCount)
            setTotalTransactionCount(response.data.totalAddressCount)

        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };

    const fetchWalletData = async (startDate, endDate) => {
        try {

            const response = await axios.post('https://apiv4.goqii.com/uht/wallet_data', {
                startDate: startDate,
                endDate: endDate
            });
            console.log('API response 2:', response.data.addressCount);
            setTotalWallet(response.data.addressCount)
            setTotalWalletCount(response.data.totalAddressCount)

        } catch (error) {
            console.error('Error fetching transactions:', error);
        }
    };



    useEffect(() => {
        const fetchEtherlinkTransactions = async () => {
            try {

                const response = await fetch('https://explorer.etherlink.com/api/v2/addresses/0xa45b42A4855AC5cFefc64fD7079DA6416511eC22/transactions?filter=to');
                const data = await response.json();
                setTransactions(data.items);

            } catch (error) {
                console.error('Error fetching transactions:', error);
            }
        };
        fetchEtherlinkTransactions();
    }, []);

    useEffect(() => {
        if (dateRange === 'custom') {
            if (startDate && endDate) {
                fetchTransactions(startDate, endDate);
                fetchWalletData(startDate, endDate);
            }
        } else {
            // Here you can add logic to determine startDate and endDate based on the selected dateRange
            let calculatedStartDate, calculatedEndDate;
            const today = new Date();
            switch (dateRange) {
                case 'today':
                    calculatedStartDate = calculatedEndDate = today.toISOString().split('T')[0];
                    break;
                case 'yesterday':
                    const yesterday = new Date(today);
                    yesterday.setDate(today.getDate() - 1);
                    calculatedStartDate = calculatedEndDate = yesterday.toISOString().split('T')[0];
                    break;
                case 'thisWeek':
                    const startOfWeek = new Date(today);
                    startOfWeek.setDate(today.getDate() - today.getDay());
                    calculatedStartDate = startOfWeek.toISOString().split('T')[0];
                    calculatedEndDate = today.toISOString().split('T')[0];
                    break;
                case 'thisMonth':
                    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                    calculatedStartDate = startOfMonth.toISOString().split('T')[0];
                    calculatedEndDate = today.toISOString().split('T')[0];
                    break;
                default:
                    break;
            }
            fetchTransactions(calculatedStartDate, calculatedEndDate);
            fetchWalletData(calculatedStartDate, calculatedEndDate)
        }

    }, [dateRange, startDate, endDate]);

    useEffect(() => {
        const fetchAndUpdateChart = async () => {
            const transactionsByDate = await fetchChartTransactions();
            updateChart(transactionsByDate);
        };

        fetchAndUpdateChart();
    }, []);

    const handleShowMoreClick = () => setShowAllTransactions(true);
    const handleShowLessClick = () => setShowAllTransactions(false);

    // Determine the number of transactions to show based on the state
    const displayedTransactions = showAllTransactions ? transactions : transactions.slice(0, 10);

    const handleTransactionClick = (hash) => {
        if (expandedTransaction === hash) {
            setExpandedTransaction(null); // Collapse if already expanded
        } else {
            setExpandedTransaction(hash); // Expand to show full address
        }
    };

    return (
        <div className="container">
            <div className="header">
                <h1>xUHT Token Dashboard</h1>
            </div>

            <div className="stats">
                <div className="stat-box">
                    <h2>{totalWalletCount}</h2>
                    <p>Toal Wallets</p>
                </div>
                <div className="stat-box">
                    <h2>{totalTransactionCount}</h2>
                    <p>Total Transaction</p>
                </div>
            </div>


            {/* <div className="filter-bar">
                <select id="chainFilter" value={selectedChain} onChange={(e) => setSelectedChain(e.target.value)}>
                    <option value="AllChain">All Chains</option>
                    <option value="EtherLink">EtherLink</option>
                    <option value="Polygon">Polygon</option>
                </select>
            </div> */}
            <div className="date-range-container">
                <select id="dateRange" value={dateRange} onChange={(e) => {
                    const value = e.target.value;
                    setDateRange(value);
                    if (value !== 'custom') {
                        setStartDate('');
                        setEndDate('');
                    }
                }}>
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                    <option value="thisWeek">This Week</option>
                    <option value="thisMonth">This Month</option>
                    <option value="custom">Custom Date</option>
                </select>

                {dateRange === 'custom' && (
                    <>
                        <input type="date" id="startDate" value={startDate} onChange={(e) => {
                            setStartDate(e.target.value);
                        }} />
                        <input type="date" id="endDate" value={endDate} onChange={(e) => {
                            setEndDate(e.target.value);
                        }} />
                    </>
                )}
            </div>


            <div className="stats">
                <div className="stat-box">
                    <h2>{totalWallet}</h2>
                    <p>Unique Wallets</p>
                </div>
                <div className="stat-box">
                    <h2>{totalTransaction}</h2>
                    <p>Transactions</p>
                </div>

                {/* <div className="stat-box">
                    <h2>1 Gwei</h2>
                    <p>Gas Tracker</p>
                </div>
                <div className="stat-box">
                    <h2>74,887</h2>
                    <p>Active Wallets</p>
                </div> */}
            </div>

            <div className="chart-container">
                <h2>Daily Transactions Overview</h2>
                <canvas id="transactionsChart"></canvas>
            </div>

            {/* <div className="latest-transactions">
                <h2>Latest On-Chain Transactions</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Transaction Hash</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Value</th>
                            <th>Fee</th>
                        </tr>
                    </thead>
                    <tbody>
                        {displayedTransactions.map((transaction, index) => (
                            <tr key={index}>
                                <td onClick={() => handleTransactionClick(transaction.hash)}>
                                    {expandedTransaction === transaction.hash
                                        ? transaction.hash
                                        : `${transaction.hash.slice(0, 6)}...${transaction.hash.slice(-4)}`}
                                </td>
                                <td>{transaction.tx_types.join(', ')}</td>
                                <td>{transaction.status === 'ok' ? 'Success' : 'Failed'}</td>
                                <td>{transaction.value} XUHT</td>
                                <td>{(transaction.fee.value / 1e18).toFixed(6)} XUHT</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="transactions-link">
                    {showAllTransactions ? (
                        <button onClick={handleShowLessClick}>Show Less</button>
                    ) : (
                        <button onClick={handleShowMoreClick}>View All Transactions</button>
                    )}
                </div>
            </div> */}

            {/* <div className="latest-wallets">
                <h2>Latest Active Wallets</h2>
                <table>
                    <thead>
                        <tr>
                            <th>Wallet Address</th>
                            <th>Last Activity</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0x5d...BD4b</td>
                            <td>1 minute ago</td>
                            <td>1000 XUHT</td>
                        </tr>
                        <tr>
                            <td>0x3e...2fb5</td>
                            <td>2 minutes ago</td>
                            <td>500 XUHT</td>
                        </tr>
                        <tr>
                            <td>0x1eb...997e</td>
                            <td>5 minutes ago</td>
                            <td>250 XUHT</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}

        </div>
    );
};

export default Dashboard;
