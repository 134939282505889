import React from 'react'
import Navbar from './Navbar';
import ThreeComp from './ThreeComp';
import TwoComp from './TwoComp';
import OneComp from './OneComp';
import LastComp from './LastComp';


const Home = () => {
    return (
        <div>
            <Navbar />
            <ThreeComp />
            <TwoComp />
            <OneComp />
            <LastComp />
        </div>
    )
}

export default Home
