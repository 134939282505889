import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import { MetaMaskInstallModal } from './MetaMaskInstallModal'
import MetaMaskOnboarding from '@metamask/onboarding';
import { Modal } from "react-bootstrap";
import { Button, Card } from "react-bootstrap";
import { Alert } from "bootstrap";
// import { chain } from '../utils/helper'
// import { Hash } from './Hash';
import { ethers, providers } from "ethers";
import truncateEthAddress from "truncate-eth-address";
import axios from "axios";
import { FaWallet, FaEthereum } from 'react-icons/fa';
import metamaskIcon from "../images/metamask-icon.svg"
import { Dropdown } from 'react-bootstrap';


function Navbar() {
  const handleLinkClick = () => {
    // Simulate link click
    // You can replace "/new-route" with the actual route you want to navigate to
    window.location.reload();
    window.location.href = "/";
  };

  const handleLinkClick1 = () => {
    // Simulate link click
    // You can replace "/new-route" with the actual route you want to navigate to
    window.location.reload();
    window.location.href = "/our-partners";
  };

  const handleLinkClick2 = () => {
    // Simulate link click
    // You can replace "/new-route" with the actual route you want to navigate to
    window.location.reload();
    window.location.href = "/fog-foundation";
  };

  const [modalShow, setModalShow] = useState(false);
  const [isMetaMaskOnboarded] = useState(MetaMaskOnboarding.isMetaMaskInstalled());
  const { ethereum } = window;
  const [connectedWalletAddress, setConnectedWalletAddress] = useState(); // checking if wallet address is connected
  const [connectWalletLoading, setConnectWalletLoading] = useState(false);
  const [metaMaskIsReady, setMetaMaskIsReady] = useState(false);
  const [chainId, setChainId] = useState();
  const [changeChainLoading, setChangeChainLoading] = useState(false);
  const [chainChanged, setChainChanged] = useState(false);
  const [data, setdata] = useState({
    address: null,
    Balance: null,
  });

  const [showModal, setShowModal] = useState(false);

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  console.log("connectedWalletAddress", connectedWalletAddress);
  // const { children } = props
  useEffect(() => {
      try {
          if (isMetaMaskOnboarded) {
              (async () => {
                  recognizeChainChange()
                  const [account] = await window.ethereum?.request({ method: 'eth_accounts' })
                  setConnectedWalletAddress(account)
                  window.ethereum?.on('accountsChanged', async (accounts) => {
                      if (!accounts.length) setMetaMaskIsReady(false)
                      setConnectedWalletAddress(accounts[0])
                  });
              })();
          }
      } catch (error) {
          console.log(error)
      }
      //eslint-disable-next-line
  }, [])

  // useEffect(() => {

  //     (async () => {
  //         const id = await recognizeChainId()
  //         if (id !== chainId) {
  //             setChainId(id)
  //         }
  //         await initWallet()
  //         if (isMetaMaskOnboarded && chainId === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID && connectedWalletAddress) {
  //             setMetaMaskIsReady(true)
  //         }
  //         else setMetaMaskIsReady(false)
  //     })()
  //     //eslint-disable-next-line
  // }, [connectWalletLoading, connectedWalletAddress, chainId, metaMaskIsReady, chainChanged]);

  const setWalletAddress = async () => {
    const accounts = await window.ethereum?.request({ method: "eth_accounts" });
    setConnectedWalletAddress(accounts[0]);
  };

  const initWallet = async () => {
    try {
      await setWalletAddress();
    } catch (error) {
      //  Alert(error.message);
      alert("Something went wrong"); 
      console.log(error);
    }
  };

  const connectWallet = async () => {
    try {
      // setConnectWalletLoading(true)
      const accounts = await window.ethereum?.request({
        method: "eth_requestAccounts",
      });
      setConnectedWalletAddress(accounts[0]);
      console.log("connectWallet:", accounts[0]);
      // var account = accounts[0]
      // if (window.ethereum) {
      //     window.ethereum
      //         .request({
      //             method: "eth_getBalance",
      //             params: [accounts[0], "latest"]
      //         })
      //         .then((balance) => {
      //             // Setting balance
      //             setdata({
      //                 Balance: ethers.formatEther(balance),
      //             });
      //             console.log("data", data)
      //         });
      // };
      sendDataToApi(accounts[0]);
      // console.log("Signature:", signature);
      // console.log("accounts", accounts[0]);
      // console.log("Signature:", signature);
      // const provider = new ethers.BrowserProvider(window.ethereum);
      // console.log("Signature:", signature);
      // const signer = provider.getSigner();
      // console.log("Signature:", signature);
      // const message = "Signature request"; // Modify the message as per your requirement
      // console.log("Signature:", signature);
      // const signature = signer.signMessage(message);
      // console.log("Signature:", signature);
      // getbalance(accounts[0]);

      // setConnectWalletLoading(false)
    } catch (error) {
      alert(error.message);
    }
  };

  const getconnectWallet = async () => {
    try {
      setConnectWalletLoading(true);
      const accounts = await window.ethereum?.request({
        method: "eth_accounts",
      });
      if (accounts.length > 0) {
        setConnectedWalletAddress(accounts[0]);
        console.log("accounts", accounts[0]);
      } else {
        console.log("Connect to Metamask");
      }

      setConnectWalletLoading(false);
    } catch (error) {
      Alert(error.message);
    }
  };

  const recognizeChainChange = async () => {
      await ethereum?.on('chainChanged', async () => {
          const currentChainId = await ethereum?.request({ method: 'eth_chainId' })
          setChainId(currentChainId)
          setChainChanged(true)
          setMetaMaskIsReady(false)
          if (isMetaMaskOnboarded && currentChainId === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID && connectedWalletAddress) setMetaMaskIsReady(true)
          else setMetaMaskIsReady(false)
      });
  }

  const recognizeChainId = async () => {
    // default chain id is set to BSC TESTNET (0x61) in .env file
    // configs for mainnet and testnet are in "utils/helper.js"
    try {
      const currentChainId = await ethereum?.request({ method: "eth_chainId" });
      return currentChainId;
    } catch (error) {
      console.log(error);
      Alert(error.message);
    }
  };

  function ConnectToPhoneMetaMask({ setUserAddress }) {
    console.log("mobile");
    const YOUR_ORIGIN = process.env.REACT_APP_FRONTEND_ORIGIN;
    const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + YOUR_ORIGIN; // put your origin here without http:// ro https://
    return (
      <a href={metamaskAppDeepLink}>
        <a className="nav-link smoothScroll wallet-button">
          Connect to MetaMask
        </a>
      </a>
    );
  }

  // const changeChain = async () => {
  //     try {
  //         const id = await recognizeChainId()
  //         if (id !== process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID) {
  //             setChangeChainLoading(true)
  //             await addChain() // If it wasn't the specific chain
  //             await ethereum?.request({
  //                 method: 'wallet_switchEthereumChain',
  //                 params: [{ chainId: process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID }], // chainId must be in hexadecimal numbers // BNB TESTNET = 0x61 // BNB MAINNET = 0x38 // ETH MAINNET = 0x1
  //             })
  //             if (isMetaMaskOnboarded && id === process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID) setMetaMaskIsReady(true)
  //             setChangeChainLoading(false)
  //         }
  //     }
  //     catch (error) {
  //         setChangeChainLoading(false)
  //         console.log(error)
  //         if (error.code === -32002) {
  //             Alert('Request is pending, please open MetaMask.')
  //             setChangeChainLoading(true)
  //         }
  //         setMetaMaskIsReady(false)
  //     }
  // }

  const getbalance = (address) => {
    // Requesting balance method
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_getBalance",
          params: [address, "latest"],
        })
        .then((balance) => {
          // Setting balance
          setdata({
            Balance: ethers.formatEther(balance),
          });
        });
    }
  };

  const btnhandler = async () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const message = "Signature request"; // Modify the message as per your requirement

      const signature = signer.signMessage(message);
      console.log("Signature:", signature);
    } else {
      alert("install metamask extension!!");
    }
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address data
    setdata({
      address: account,
    });

    // Setting a balance
    getbalance(account);
  };

  const addToken = async () => {
    try {
      await ethereum?.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: "0x295AB84c45545c6c55aA86711e9B06a6768B5eB4",
            symbol: "EP7",
            decimals: 18,
            image: "",
          },
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // const addChain = async () => {
  //     try {
  //         await ethereum?.request({
  //             method: 'wallet_addEthereumChain',
  //             params: [chain],
  //         });
  //     } catch (error) {
  //         console.error(error);
  //     }
  // }

  const sendDataToApi = async (account) => {
    try {
      if (account != undefined || account != null) {
        const apiEndpoint = `//apiv2.goqii.com/web3/save_metamack_addr?address=${account}&balance=0`;
        const response = await axios.post(apiEndpoint);
        // Handle the response if needed
        console.log("API Response:", response.data);
      }
    } catch (error) {
      console.error("Error sending data to API:", error);
    }
  };

  // function ConnectModal(props) {
  //     const onboarding = new MetaMaskOnboarding();
  //     console.log("web")
  //     useEffect(() => {
  //         return () => {
  //             onboarding.stopOnboarding()
  //         }
  //         //eslint-disable-next-line
  //     }, [])

  //     const handleConnectToWallet = () => {
  //         onboarding.startOnboarding();
  //     }

  //     const isMobileDevice = () => {
  //         return 'ontouchstart' in window || 'onmsgesturechange' in window;
  //     }

  //     return (
  //         <div className="bg-dark">
  //             <Modal
  //                 {...props}
  //                 size="md"
  //                 aria-labelledby="contained-modal-title-vcenter"
  //                 contentClassName='bg-dark p-1'
  //                 centered
  //             >
  //                 <Modal.Header className={` text-white d-flex justify-content-end  cursor-default `}>
  //                     <button className='btn btn-danger text-center border-0 ' onClick={props.onHide}><span className='font-weight-bold'>×</span></button>
  //                 </Modal.Header>
  //                 <Modal.Body className="d-flex justify-content-center p-4 pb-0 my-2 bg-dark">
  //                     <main className=" site-content text-center col-10 mb-5" >
  //                         <div className="site-content__center ">
  //                             <h3 className="text-white">
  //                                 Attention !
  //                             </h3>
  //                             <p className="text-white" style={{ fontSize: '19px' }} >
  //                                 You should reload the page after installing MetaMask
  //                             </p>
  //                             {
  //                                 isMobileDevice() ?
  //                                     <ConnectToPhoneMetaMask />
  //                                     : <Button className="btn btn-secondary module-button" onClick={handleConnectToWallet}>Install Wallet</Button>
  //                             }
  //                             <Button className="btn btn-secondary module-button" onClick={handleConnectToWallet}>Install Wallet</Button>
  //                         </div>
  //                     </main>
  //                 </Modal.Body>
  //             </Modal>
  //         </div>
  //     );
  // }
  // const handleConnectWallet = () => {
  //     const onboarding = new MetaMaskOnboarding();
  //     onboarding.startOnboarding();
  // };

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  // Close menu when clicking outside
  useEffect(() => {
    const closeMenu = (e) => {
      if (showMobileMenu && !e.target.closest('.menu-button')) {
        setShowMobileMenu(false);
      }
    };
    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, [showMobileMenu]);
  return (
    <>
      <div className="bg"></div>
      <div className="navbar-no-shadow">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-no-shadow-container w-nav"
        >
          <div className="container-regular">
            <div className="navbar-wrapper">
              <a href="#" className="navbar-brand w-nav-brand">
                <img
                  src="images/uht-20logo.png"
                  loading="lazy"
                  width="148"
                  alt=""
                  className="image"
                />
              </a>
              <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                <ul role="list" className="nav-menu w-list-unstyled">
                  <li>
                    <a href="#" className="nav-link">
                      Home
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://uht.gitbook.io/universal-health-token/"
                      target="blank"
                      className="nav-link"
                    >
                      Whitepaper
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToSection('our-partners')} className="nav-link">
                      Our Partners
                    </a>
                  </li>
                  <li>
                    <a href="#" onClick={() => scrollToSection('fog-foundation')} className="nav-link">
                      FoG Foundation
                    </a>
                  </li>
                  <li className="mobile-margin-top-10">

                  {isMetaMaskOnboarded && !connectedWalletAddress &&
                    <div className="nav-button-wrapper">
                      <a
                        href="#"
                        // onClick={handleModalOpen}
                        className="button-primary purple small w-button"
                        onClick={connectWallet}
                      >
                        CONNECT WALLET 
                      </a>
                    </div>
                  }
                    


                    {
                      isMetaMaskOnboarded && connectedWalletAddress &&
                          // chainId !== process.env.REACT_APP_BLOCKCHAIN_NETWORK_ID &&
                        <div className="nav-button-wrapper">
                          <a onClick={btnhandler} style={{ cursor: 'pointer' }} 
                          className="button-primary purple small w-button"
                          >
                              {truncateEthAddress(connectedWalletAddress)}
                          </a>
                       </div>
                        }
                  </li>
                </ul>
              </nav>
              {/* <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu"></div>
              </div> */}
              <div className="menu-button w-nav-button">
              <div className="menu-button w-nav-button" onClick={toggleMobileMenu}>
        <div className="w-icon-nav-menu"></div>
      </div>

      {showMobileMenu && (
        <div 
          style={{
            position: 'absolute',
            top: '100%',
            right: '0',
            backgroundColor: '#fff',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            borderRadius: '8px',
            padding: '10px',
            zIndex: 1000,
            minWidth: '200px',
            animation: 'slideDown 0.3s ease-out',
          }}
        >
          <a href="#" className="mobile-nav-link">Home</a>
          <a href="https://uht.gitbook.io/universal-health-token/" target="blank" className="mobile-nav-link">Whitepaper</a>
          <a href="#" onClick={() => { scrollToSection('our-partners'); toggleMobileMenu(); }} className="mobile-nav-link">Our Partners</a>
          <a href="#" onClick={() => { scrollToSection('fog-foundation'); toggleMobileMenu(); }} className="mobile-nav-link">FoG Foundation</a>
          {isMetaMaskOnboarded && !connectedWalletAddress && (
            <a 
              href="#" 
              onClick={(e) => { e.preventDefault(); connectWallet(); toggleMobileMenu(); }}
              className="mobile-nav-button"
            >
              CONNECT WALLET
            </a>
          )}
          {isMetaMaskOnboarded && connectedWalletAddress && (
            <a 
              href="#" 
              onClick={(e) => { e.preventDefault(); btnhandler(); toggleMobileMenu(); }}
              className="mobile-nav-button"
            >
              {truncateEthAddress(connectedWalletAddress)}
            </a>
          )}
        </div>
      )}

      <style jsx>{`
        .mobile-nav-link {
          display: block;
          padding: 10px 15px;
          color: #333;
          text-decoration: none;
          transition: background-color 0.3s;
        }
        .mobile-nav-link:hover {
          background-color: #f0f0f0;
        }
        .mobile-nav-button {
          display: block;
          margin-top: 10px;
          padding: 10px 15px;
          background-color: #004a88;
          color: white;
          text-align: center;
          text-decoration: none;
          border-radius: 5px;
          transition: background-color 0.3s;
        }
        .mobile-nav-button:hover {
          background-color: #003366;
        }
        @keyframes slideDown {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
      `}</style>
      </div>
            </div>
          </div>
        </div>
      </div>

      <Modal 
      show={showModal} 
      onHide={handleModalClose} 
      centered
      style={{ backgroundColor: 'rgba(230, 247, 255, 0.5)' }}
    >
      <Modal.Header closeButton style={{ backgroundColor: '#e6f7ff', borderBottom: 'none' }}>
        <Modal.Title>Connect to MetaMask</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: '#e6f7ff', padding: '20px' }}>
        <div style={{ 
          display: "flex", 
          justifyContent: "space-between", 
          alignItems: "center", 
          width: "100%",
          padding: '15px',
          borderRadius: '8px',
          backgroundColor: 'white',
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={metamaskIcon} alt="MetaMask" style={{ width: '30px', height: '30px', marginRight: '15px' }} />
            <p style={{ margin: 0, fontWeight: 'bold' }}>MetaMask</p>
          </div>
          <Button 
            variant="primary" 
            disabled
            style={{ 
              backgroundColor: '#e6f7ff', 
              borderColor: '#007bff',
              color: '#007bff',
              opacity: 0.65
            }}
          >
            Connect
          </Button>
        </div>
        <p style={{ marginTop: '15px', textAlign: 'center', color: '#666' }}>
          Please make sure you have MetaMask installed and configured.
        </p>
      </Modal.Body>
    </Modal>
    </>
  );
}

export default Navbar;
