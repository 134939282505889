import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const StyledSliderSection = styled.div`
  * {
    -webkit-font-smoothing: antialiased;
  }
  .slick-prev, .slick-next {
    width: 35px !important;
    height: 35px !important;
    border: 0px solid #fff !important;
    border-radius: 50px !important;
    font-family: inherit !important;
  }
  .slick-slide img {
    display: inline-block;
  }
  .slick-list {
    overflow: visible;
  }
  .slick-track {
    cursor: grab;
    height: 600px;
    background: none; !important
  }
    .slick-test div {
    background-color: none !important;
}
  .slick-slide {
    opacity: .5;
    transition: opacity 800ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: scale(.85);
    outline: none;
    border: none;
  }
  .slick-slide.slick-center {
    opacity: 1;
    transform: scale(1);
    z-index: -100;
    box-shadow: 0 13px 21px rgba(17, 26, 31, 0.5);
    cursor: -webkit-grab;
  }
  .slick-dots {
    bottom: -70px;
  }
  .slick-dots li button {
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: #004A88;
    margin: 15px;
  }
  .slick-dots li.slick-active button {
    transform: scale(1.3);
    background: #9aa0a7;
    box-shadow: 0 0 0 7px #eff9fa, 0 0 0 8px #004A88;
    background: #004A88;
  }
  .slick-dots li button:before {
    display: none;
  }
  
  .collection-item {
    position: relative;
    height: 600px;
    
    background: transparent; !important
  }
  
  .bg_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
  }
  
  @media only screen and (max-width: 860px) {
    .collection-item, .bg_image {
      height: 600px;
    }
  }
  @media only screen and (max-width: 600px) {
    .collection-item, .bg_image {
      height: 600px;
    }
  }
  @media only screen and (max-width: 400px) {
    .collection-item, .bg_image {
      height: 600px;
    }
  }
`;

const TwoComp = () => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    centerMode: true,
    centerPadding: '0',
    
    arrows: false,
    responsive: [
      {
        // breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  return (
    <>
      <div className="content earn">
        <div className="sublabel-wrapper">
          <img
            src="images/group-201000009427.svg"
            loading="lazy"
            width="20"
            alt=""
            className="image-2"
          />
          <p className="sublabel">Get Results</p>
        </div>
        <p className="heading---content middle">
          Engage, Earn, and Enjoy Wellness with UHT
        </p>
        <p className="paragraph black center-allign _600px">
          Unlock future wealth through Universal Health Token. Backed by Animoca
          Brands, Powered by GOQii
        </p>
      </div>
      <StyledSliderSection className="slider-section">
        <Slider {...sliderSettings} className="slider">
          <div className="collection-item">
            <div className="bg_image" style={{backgroundImage: 'url("images/image-202.png")'}}></div>
            <div className="slider-text">
              <p className="heading---content small minimum-height">
                Reach Your Goals with UHT
              </p>
              <p className="paragraph on-slider-text">
                Enjoy gamified health, own your data with Web3, and track
                comprehensive wellness, all while staying secure and private
                through the decentralised structure.
              </p>
            </div>
          </div>
          <div className="collection-item">
            <div className="bg_image" style={{backgroundImage: 'url("images/woman-eating-meat-salad-with-tomato-julienne-slices-served-with-chips-202.png")'}}></div>
            <div className="slider-text">
              <p className="heading---content small minimum-height">
                Maximise Wellness
              </p>
              <p className="paragraph on-slider-text">
                Earn tokens for healthy activities, access professional
                services, receive healthcare rewards, and shop in our
                health-focused marketplace.
              </p>
            </div>
          </div>
          <div className="collection-item">
            <div className="bg_image" style={{backgroundImage: 'url("images/woman-exercising-through-online-programm-gym-201-1.png")'}}></div>
            <div className="slider-text">
              <p className="heading---content small minimum-height">
                The Power of Preventative Health
              </p>
              <p className="paragraph on-slider-text">
                Enjoy the full experience of GOQii seamlessly bridging Web2
                and Web3
              </p>
            </div>
          </div>
        </Slider>
      </StyledSliderSection>
    </>
  );
};

export default TwoComp;